import { useCallback, useEffect, useMemo, useState } from 'react';
import RouterContext from './contexts/routerContext';
import { extractPathFromSearchParams } from './utils';
import { fetchNewLocation } from './push';

function QueryRouter({ children, pathParam, onRouteChangeStart = () => {} }) {
  const currentLocation = extractPathFromSearchParams(window.location.search, pathParam);
  const [location, setLocation] = useState(currentLocation);
  const [rawLocation, setRawLocation] = useState(window.location.search);

  const handlePush = useCallback((arg) => {
    console.debug('handlePush: Received with following args', arg);
    const { newRawLocation, newLocation, shallowMode } = fetchNewLocation(arg, pathParam);
    console.debug(`handlePush: Processing with following args: newRawLocation: ${newRawLocation}, 
    newLocation: ${newLocation}, shallowMode:${shallowMode}`);

    if (shallowMode) {
      // In shallow mode, we don't want to update the location state
      // so we don't trigger a re-render
      window.history.replaceState({}, '', newRawLocation);
    } else {
      onRouteChangeStart();
      window.history.pushState({}, '', newRawLocation);
      setRawLocation(newRawLocation);
      setLocation(newLocation);
    }
  }, []);

  const handleHashChange = useCallback(() => {
    setLocation(currentLocation);
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', handleHashChange);
    return () => window.removeEventListener('popstate', handleHashChange);
  }, [handleHashChange]);

  const value = useMemo(() => {
    return {
      location,
      push: handlePush,
      pathParam: pathParam,
      rawLocation: rawLocation,
    };
  }, [location, rawLocation, handlePush, pathParam]);

  return <RouterContext.Provider value={value}>{children}</RouterContext.Provider>;
}

export default QueryRouter;
